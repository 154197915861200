var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{staticClass:"p-4"},[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('h5',[_vm._v(" Work Orders ("+_vm._s(_vm.count)+")")])]),_c('CCol',{attrs:{"lg":"6"}},[_c('CButton',{class:'float-lg-right block '+_vm.showCollapse ? 'collapsed' : null,staticStyle:{"float":"right"},attrs:{"size":"sm","aria-expanded":_vm.showCollapse ? 'true' : 'false',"aria-controls":"collapse1","color":"info"},on:{"click":function($event){_vm.showCollapse = !_vm.showCollapse}}},[_c('i',{staticClass:"fa fa-search"}),_vm._v("Filter"),_c('i',{class:_vm.showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'})]),(_vm.config.getPermission('workorder_reports').download)?_c('CButton',{staticClass:"float-lg-right",staticStyle:{"margin-right":"10px"},attrs:{"size":"sm","color":"primary"},on:{"click":_vm.download}},[_c('i',{staticClass:"fa fa-download"}),_vm._v("Download ")]):_vm._e()],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCollapse',{staticClass:"mt-2",attrs:{"id":"collapse1","show":_vm.showCollapse}},[_c('CCard',{staticClass:"p-4"},[_c('Search',{on:{"depot-search-query":_vm.search}})],1)],1)],1)],1),_c('hr'),_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('div',{staticClass:"table-responsive-sm table-responsive-md"},[_c('CTabs',{attrs:{"variant":"pills","active-tab":0}},[_c('CTab',{attrs:{"title":"All"},nativeOn:{"click":function($event){return _vm.tabChanged($event, 'all')}}},[_c('br'),_c('CDataTable',{attrs:{"loading":_vm.isLoading,"items":_vm.dataList.data,"fields":_vm.company_type == 'trucking' ? _vm.fields_trucking : _vm.fields,"hover":"","outlined":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_c('CBadge',{staticStyle:{"margin-top":"10px"},attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"total_cost",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm._f("CurrencyFormatWithSign")(_vm._f("computeTotal")(item.labor_amount,item.work_order_part_request)))+" ")])]}}])}),_c('CPagination',{attrs:{"active-page":_vm.currentPage,"pages":Math.ceil(_vm.dataList.total / 20),"activePage":_vm.currentPage},on:{"update:activePage":[function($event){_vm.currentPage=$event},_vm.updatePage],"update:active-page":function($event){_vm.currentPage=$event}}})],1),_c('CTab',{attrs:{"title":"Draft"},nativeOn:{"click":function($event){return _vm.tabChanged($event, 'draft')}}},[_c('br'),_c('CDataTable',{attrs:{"items":_vm.dataListDraft.data,"fields":_vm.company_type == 'trucking' ? _vm.fields_trucking : _vm.fields,"hover":"","striped":"","loading":_vm.isLoading,"outlined":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_c('CBadge',{staticStyle:{"margin-top":"10px"},attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"total_cost",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm._f("CurrencyFormatWithSign")(_vm._f("computeTotal")(item.labor_amount,item.work_order_part_request)))+" ")])]}}])}),_c('CPagination',{attrs:{"active-page":_vm.currentPageDraft,"pages":Math.ceil(_vm.dataListDraft.total / 20),"activePage":_vm.currentPageDraft},on:{"update:activePage":[function($event){_vm.currentPageDraft=$event},_vm.updatePageDraft],"update:active-page":function($event){_vm.currentPageDraft=$event}}})],1),_c('CTab',{attrs:{"title":"Submitted"},nativeOn:{"click":function($event){return _vm.tabChanged($event, 'submitted')}}},[_c('br'),_c('CDataTable',{attrs:{"items":_vm.dataListSubmitted.data,"fields":_vm.company_type == 'trucking' ? _vm.fields_trucking : _vm.fields,"hover":"","striped":"","loading":_vm.isLoading,"outlined":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_c('CBadge',{staticStyle:{"margin-top":"10px"},attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"total_cost",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm._f("CurrencyFormatWithSign")(_vm._f("computeTotal")(item.labor_amount,item.work_order_part_request)))+" ")])]}}])}),_c('CPagination',{attrs:{"active-page":_vm.currentPageSubmitted,"pages":Math.ceil(_vm.dataListSubmitted.total / 20),"activePage":_vm.currentPageSubmitted},on:{"update:activePage":[function($event){_vm.currentPageSubmitted=$event},_vm.updatePageSubmitted],"update:active-page":function($event){_vm.currentPageSubmitted=$event}}})],1),_c('CTab',{attrs:{"title":"Approved"},nativeOn:{"click":function($event){return _vm.tabChanged($event, 'approved')}}},[_c('br'),_c('CDataTable',{attrs:{"items":_vm.dataListApproved.data,"fields":_vm.company_type == 'trucking' ? _vm.fields_trucking : _vm.fields,"hover":"","striped":"","loading":_vm.isLoading,"outlined":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_c('CBadge',{staticStyle:{"margin-top":"10px"},attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"total_cost",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm._f("CurrencyFormatWithSign")(_vm._f("computeTotal")(item.labor_amount,item.work_order_part_request)))+" ")])]}}])}),_c('CPagination',{attrs:{"active-page":_vm.currentPageApproved,"pages":Math.ceil(_vm.dataListApproved.total / 20),"activePage":_vm.currentPageApproved},on:{"update:activePage":[function($event){_vm.currentPageApproved=$event},_vm.updatePageApproved],"update:active-page":function($event){_vm.currentPageApproved=$event}}})],1),_c('CTab',{attrs:{"title":"On Service"},nativeOn:{"click":function($event){return _vm.tabChanged($event, 'on service')}}},[_c('br'),_c('CDataTable',{attrs:{"items":_vm.dataListOnService.data,"fields":_vm.company_type == 'trucking' ? _vm.fields_trucking : _vm.fields,"hover":"","striped":"","loading":_vm.isLoading,"outlined":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_c('CBadge',{staticStyle:{"margin-top":"10px"},attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"total_cost",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm._f("CurrencyFormatWithSign")(_vm._f("computeTotal")(item.labor_amount,item.work_order_part_request)))+" ")])]}}])}),_c('CPagination',{attrs:{"active-page":_vm.currentPageOnService,"pages":Math.ceil(_vm.dataListOnService.total / 20),"activePage":_vm.currentPageOnService},on:{"update:activePage":[function($event){_vm.currentPageOnService=$event},_vm.updatePageOnService],"update:active-page":function($event){_vm.currentPageOnService=$event}}})],1),_c('CTab',{attrs:{"title":"Completed"},nativeOn:{"click":function($event){return _vm.tabChanged($event, 'completed')}}},[_c('br'),_c('CDataTable',{attrs:{"items":_vm.dataListCompleted.data,"fields":_vm.company_type == 'trucking' ? _vm.fields_trucking : _vm.fields,"hover":"","striped":"","loading":_vm.isLoading,"outlined":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_c('CBadge',{staticStyle:{"margin-top":"10px"},attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"total_cost",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm._f("CurrencyFormatWithSign")(_vm._f("computeTotal")(item.labor_amount,item.work_order_part_request)))+" ")])]}}])}),_c('CPagination',{attrs:{"active-page":_vm.currentPageCompleted,"pages":Math.ceil(_vm.dataListCompleted.total / 20),"activePage":_vm.currentPageCompleted},on:{"update:activePage":[function($event){_vm.currentPageCompleted=$event},_vm.updatePageCompleted],"update:active-page":function($event){_vm.currentPageCompleted=$event}}})],1),_c('CTab',{attrs:{"title":"Cancelled"},nativeOn:{"click":function($event){return _vm.tabChanged($event, 'cancelled')}}},[_c('br'),_c('CDataTable',{attrs:{"items":_vm.dataListCancelled.data,"fields":_vm.company_type == 'trucking' ? _vm.fields_trucking : _vm.fields,"hover":"","striped":"","loading":_vm.isLoading,"outlined":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_c('CBadge',{staticStyle:{"margin-top":"10px"},attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"total_cost",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm._f("CurrencyFormatWithSign")(_vm._f("computeTotal")(item.labor_amount,item.work_order_part_request)))+" ")])]}}])}),_c('CPagination',{attrs:{"active-page":_vm.currentPageCancelled,"pages":Math.ceil(_vm.dataListCancelled.total / 20),"activePage":_vm.currentPageCancelled},on:{"update:activePage":[function($event){_vm.currentPageCancelled=$event},_vm.updatePageCancelled],"update:active-page":function($event){_vm.currentPageCancelled=$event}}})],1),_c('CTab',{attrs:{"title":"Declined"},nativeOn:{"click":function($event){return _vm.tabChanged($event, 'declined')}}},[_c('br'),_c('CDataTable',{attrs:{"items":_vm.dataListDeclined.data,"fields":_vm.company_type == 'trucking' ? _vm.fields_trucking : _vm.fields,"hover":"","striped":"","loading":_vm.isLoading,"outlined":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_c('CBadge',{staticStyle:{"margin-top":"10px"},attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"total_cost",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm._f("CurrencyFormatWithSign")(_vm._f("computeTotal")(item.labor_amount,item.work_order_part_request)))+" ")])]}}])}),_c('CPagination',{attrs:{"active-page":_vm.currentPageDeclined,"pages":Math.ceil(_vm.dataListDeclined.total / 20),"activePage":_vm.currentPageDeclined},on:{"update:activePage":[function($event){_vm.currentPageDeclined=$event},_vm.updatePageDeclined],"update:active-page":function($event){_vm.currentPageDeclined=$event}}})],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }